import React, { useState } from "react";
import Select from "react-select";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const CouponFilterForm = ({ formik, handleReset, basicData }) => {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  const minDate = today.toISOString().split("T")[0];

  let couponTypeList = [
    {
      id: "2",
      label: "Agent",
    },
    { id: "1", label: "Company" },
  ];

  let statusList = [
    {
      id: "0",
      label: "Inactive",
    },
    { id: "1", label: "Active" },
  ];

  const [startDateClass, setStartDateClass] = useState("");
  const [endDateClass, setEndDateClass] = useState("");

  // Handle change for the start date picker
  const handleStartDateChange = (e) => {
    formik.handleChange(e);
    setStartDateClass(e.target.value ? "date-selected" : "");
  };

  // Handle change for the end date picker
  const handleEndDateChange = (e) => {
    formik.handleChange(e);
    setEndDateClass(e.target.value ? "date-selected" : "");
  };

  return (
    <div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Status
        </label>
        <Select
          id="status"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          name="status"
          options={basicData}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={basicData?.filter((m) => formik.values.status === m.id)}
          onChange={(value) => formik.setFieldValue("status", value?.id)}
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Coupon Type
        </label>
        <Select
          id="coupon_type"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          name="coupon_type"
          options={couponTypeList}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.label}
          value={couponTypeList?.filter(
            (m) => formik.values.coupon_type === m.id
          )}
          onChange={(value) => formik.setFieldValue("coupon_type", value?.id)}
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Valid From
        </label>
        <input
          className={`pro-input lg ${startDateClass}`}
          type="date"
          name="start_date"
          max={minDate}
          onChange={handleStartDateChange}
          value={formik.values.start_date}
        />
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Valid To
        </label>
        <input
          className={`pro-input lg ${endDateClass}`}
          type="date"
          name="end_date"
          max={new Date().toISOString().split("T")[0]}
          onChange={handleEndDateChange}
          value={formik.values.end_date}
        />
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleReset()}
        >
          Reset
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default CouponFilterForm;
