import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import CouponFilterForm from "./CouponFilterForm";
import {
  resetExtraFilters,
  setStoreFormValues,
} from "../../../../store/slices/Coupons/couponsSlice";

const useFilter = ({ setShowform, refetch, basicData }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.coupons);

  const validationSchema = Yup.object({
    start_date: Yup.date().nullable(),
    end_date: Yup.date().nullable(),
    status: Yup.string().nullable(),
    coupon_type: Yup.string().nullable(),
  });

  const initialValues = {
    start_date: state.start_date,
    end_date: state.end_date,
    coupon_type: state.coupon_type,
    status: state.status,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const { start_date, end_date, coupon_type, status } = values;

      if (
        coupon_type === "" &&
        start_date === "" &&
        end_date === "" &&
        status === ""
      ) {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        setShowform(false);
      }

      if (values) {
        dispatch(setStoreFormValues(values));
      }
    },
  });
  // useEffect(() => {
  //   // Check if category is not empty before refetching
  //   if (formik.values.category !== '') {
  //     refetch();
  //   }
  // }, [formik.values.category, refetch]);

  // useEffect(() => {
  //   // Check if coupon_type is not empty before refetching
  //   if (formik.values.coupon_type !== '') {
  //     refetch();
  //   }
  // }, [formik.values.coupon_type, refetch]);

  const renderTabContent = () => {
    return (
      <CouponFilterForm
        basicData={basicData}
        formik={formik}
        handleReset={handleReset}
      />
    );
  };

  const handleReset = () => {
    dispatch(resetExtraFilters());
    formik.resetForm();
    setShowform(false);
  };

  return {
    renderTabContent,
  };
};

export default useFilter;
